<template>
  <div>
    <div>
      <!-- 头部 -->
      <PcHomeHeader></PcHomeHeader>
      <!-- 导航栏吸顶 -->
      <transition name="el-zoom-in-top">
        <PcHeader v-show="headerShow"></PcHeader>
      </transition>

      <!-- 内容 -->
      <div class="container">
        <h1 class="seo">
          珠海傲视创新科技有限公司 - 傲视官方网站, 傲视OSAI, AI弹窗, AI插件,
          AI智能秤, AI收银秤, AI秤, AI零售, AI识别, AI精准识别, 数字商超,
          AI生鲜识别, AI零食识别, AI烘焙识别, AI团餐识别
          <h1>珠海傲视创新科技有限公司</h1>
          <h1>傲视官方网站</h1>
          <h1>傲视OSAI</h1>
          <h1>AI精准识别</h1>
          <h1>AI零售</h1>
          <h1>AI秤</h1>
          <h1>AI收银秤</h1>
          <h1>AI智能秤</h1>
          <h1>AI插件</h1>
          <h1>AI弹窗</h1>
          <h1>AI精准识别</h1>
          <h1>数字商超</h1>
          <h1>AI生鲜识别</h1>
          <h1>AI零食识别</h1>
          <h1>AI烘焙识别</h1>
          <h1>AI团餐识别</h1>
        </h1>
        <Banner ref="video"></Banner>
        <div class="link_info_container">
          <div class="link_info w">
            <div class="item one" @click="$router.push('/cloudPlatform')">
              <div class="title">{{ $t('云平台服务') }}</div>
              <div class="info">{{ $t('高效的数据分析和设备管理。') }}</div>
              <div class="infoBth">
                {{ $t('了解详情') }} <i class="el-icon-arrow-right"></i>
              </div>
            </div>
            <div class="item two" @click="goTo()">
              <div class="title">{{ $t('AI智能识别算法') }}</div>
              <div class="info">
                {{ $t('赋能行业应用，助力企业降本增效。') }}
              </div>
              <div class="infoBth">
                {{ $t('了解详情') }} <i class="el-icon-arrow-right"></i>
              </div>
            </div>
            <div
              class="item three"
              @click="$router.push('/aIBarcodeScaleSolution')"
            >
              <div class="title">{{ $t('AI条码秤解决方案') }}</div>
              <div class="info">
                {{ $t('算法、应用、硬件、OS及云平台，一站式解决方案。') }}
              </div>
              <div class="infoBth">
                {{ $t('了解详情') }} <i class="el-icon-arrow-right"></i>
              </div>
            </div>
            <div class="item four" @click="$router.push('/tensorPro')">
              <div class="title">{{ $t('3D双目摄像头') }}</div>
              <div class="info">
                {{ $t('室内外全场景的高精度双目立体3D相机。') }}
              </div>
              <div class="infoBth">
                {{ $t('了解详情') }} <i class="el-icon-arrow-right"></i>
              </div>
            </div>
          </div>
        </div>
        <div
          :class="{
            industry_solution_container: activeName == 'one',
            cameraSolutionsContainer: activeName == 'two'
          }"
        >
          <div
            class="w"
            :class="{
              industry_solution: activeName == 'one',
              cameraSolutions: activeName == 'two'
            }"
          >
            <div v-show="activeName == 'one'">
              <div class="title">{{ $t('行业解决方案') }}</div>
              <div class="info">
                {{ $t('基于AI核心算法，深入行业应用，解决用户痛点。') }}
              </div>
              <div class="infoBth" @click="tabTo">
                {{ $t('了解详情') }} <i class="el-icon-arrow-right"></i>
              </div>
            </div>
            <div v-show="activeName == 'two'">
              <div class="title">{{ $t('3D双目摄像头解决方案') }}</div>
              <div class="info">
                {{
                  $t(
                    '搭载自研双目立体视觉核心算法，在1.5到30米或0.3到15米距离内可以达到毫米级深度误差。'
                  )
                }}
              </div>
              <div class="infoBth" @click="tabTo">
                {{ $t('了解详情') }} <i class="el-icon-arrow-right"></i>
              </div>
            </div>
            <div class="tab">
              <div
                class="tabItem"
                :class="{ activate: activeName == 'one' }"
                @click="tab('one')"
              >
                {{ $t('行业解决方案') }}
              </div>
              <div
                class="tabItem"
                :class="{ activate: activeName == 'two' }"
                @click="tab('two')"
              >
                {{ $t('3D双目摄像头解决方案') }}
              </div>
            </div>
          </div>
        </div>
        <div class="company_introduction_container">
          <div class="company_introduction w" ref="leftTop">
            <div class="title_box">
              <div class="title">{{ $t('公司介绍') }}</div>
              <div class="info">
                {{
                  $t(
                    '自主研发AI视觉识别算法，整合行业应用的「人工智能科技公司」'
                  )
                }}
              </div>
            </div>
            <div class="center_img">
              <img src="../../../assets/首页/分组 4.png" alt="" />
            </div>
            <transition name="el-zoom-in-center">
              <div class="leftTop" v-if="leftTop">
                <img src="../../../assets/首页/线条 (1).png" alt="" />
                <div
                  :class="
                    $i18n.locale == 'en' ? 'en_text_info' : 'zh_text_info'
                  "
                >
                  {{
                    $t(
                      '珠海傲视创新科技有限公司于2021年3月在高新区注册成立，是一家专注于自主研发及销售AI机器视觉识别行业落地应用及解决方案的人工智能创新科技公司。'
                    )
                  }}
                </div>
              </div>
            </transition>
            <transition name="el-zoom-in-center">
              <div class="leftBottom" v-if="leftBottom">
                <img src="../../../assets/首页/线条 (2).png" alt="" />
                <div
                  :class="
                    $i18n.locale == 'en' ? 'en_text_info' : 'zh_text_info'
                  "
                >
                  {{
                    $t(
                      '公司拥有多名资深的神经网络、机器学习和机器视觉专家，均毕业于清华大学、北京大学和上海交大计算机系，是公司的核心竞争优势。'
                    )
                  }}
                </div>
              </div>
            </transition>
            <transition name="el-zoom-in-center">
              <div class="rightTop" v-if="rightTop">
                <div
                  :class="
                    $i18n.locale == 'en' ? 'en_text_info' : 'zh_text_info'
                  "
                >
                  {{
                    $t(
                      '公司致力于打造零编程、自动化、高精度、通用的AI机器视觉框架，通过AI智能学习算法赋能各个行业。'
                    )
                  }}
                </div>
                <img src="../../../assets/首页/线条 (3).png" alt="" />
              </div>
            </transition>
            <transition name="el-zoom-in-center">
              <div class="rightBottom" v-if="rightBottom">
                <div
                  :class="
                    $i18n.locale == 'en' ? 'en_text_info' : 'zh_text_info'
                  "
                >
                  {{
                    $t(
                      '目前公司专注于新零售、新能源等领域的AI人工智能技术的研发和实际应用，帮助众多客户极大地提升了经营效率。'
                    )
                  }}
                </div>
                <img src="../../../assets/首页/线条 (4).png" alt="" />
              </div>
            </transition>
          </div>
        </div>
        <!-- <a name="h2">
          <div class="goodsimg">
            <img src="../../../assets/img/goods/1产品系列.png" alt="" />
          </div>
        </a>
        <a name="h3">
          <div class="goodsimg">
            <img src="../../../assets/img/goods/2解决方案.png" alt="" />
          </div>
        </a>
        <a name="h4">
          <div class="goodsimg">
            <img src="../../../assets/img/goods/3合作伙伴.png" alt="" />
          </div>
        </a>
        <a name="h5">
          <div class="goodsimg">
            <img src="../../../assets/img/goods/4关于我们.png" alt="" />
          </div>
        </a> -->
      </div>
    </div>
  </div>
</template>

<script>
import Banner from './_com_/banner.vue'
import PcHeader from '../../../components/pcHeader'
import PcHomeHeader from '../../../components/pcHomeHeader'
export default {
  components: {
    Banner,
    PcHeader,
    PcHomeHeader
  },
  data() {
    return {
      headerShow: false, //头部吸顶是否显示
      activeName: 'one', //tab选择
      navShow: false,
      lang: localStorage.getItem('lang'), // 当前语言
      leftTop: false,
      rightBottom: false,
      leftBottom: false,
      rightTop: false
    }
  },
  methods: {
    //算法的锚点跳转
    algorithmJump(val) {
      localStorage.setItem('toId', val)
      this.$router.push({ path: '/aiCoreAlgorithms', query: { t: Date.now() } })
    },
    tab(val) {
      this.activeName = val
    },
    goTo() {
      localStorage.setItem('toId', '')
      this.$router.push('/aiCoreAlgorithms')
    },
    tabTo() {
      if (this.activeName === 'one') {
        this.$router.push('/industrySolution')
      } else {
        this.$router.push('/rulerSolution')
      }
    },
    //语言切换
    languageFn(command) {
      localStorage.setItem('lang', command)
      this.$i18n.locale = command
      this.lang = command
    },
    handleScroll() {
      let h = document.documentElement.clientHeight //获取可视高度
      let el = this.$refs.leftTop.offsetHeight //获取元素高度
      let top = this.$refs.leftTop.getBoundingClientRect().top //获取元素位置
      if (top + el / 2 - h < 0) {
        setTimeout(() => {
          this.leftTop = true
        }, 800)
        setTimeout(() => {
          this.rightTop = true
        }, 1600)
        setTimeout(() => {
          this.leftBottom = true
        }, 2400)
        setTimeout(() => {
          this.rightBottom = true
        }, 3200)
        window.removeEventListener('scroll', this.handleScroll)
      }
      // if (top + el / 3 - h < 0) {
      //   this.leftTop = true
      // }
      // if (top + el / 1.5 - h < 0) {
      //   this.rightTop = true
      // }
      // if (top + el - h < 0) {
      //   this.leftBottom = true
      // }
      // if (top + el + el / 3.5 - h < 0) {
      //   this.rightBottom = true
      // }

      // if (this.$refs.leftTop.getBoundingClientRect().top - h < -220) {
      //   console.log('你好')
      // }
      // if (h - 3 < scrollTop) {
      //   this.navShow = true
      // } else {
      //   this.navShow = false
      // }
    },
    handleHomeHeader() {
      let video = this.$refs.video.$el.offsetHeight //获取视频元素高度
      let scrollTop =
        document.documentElement.scrollTop || document.body.scrollTop //获取滚动位置
      if (scrollTop >= video) {
        this.headerShow = true
      } else {
        this.headerShow = false
      }
    }
  },
  mounted() {
    window.addEventListener('scroll', this.handleScroll)
    window.addEventListener('scroll', this.handleHomeHeader)
  },
  destroyed() {
    window.removeEventListener('scroll', this.handleScroll)
    window.removeEventListener('scroll', this.handleHomeHeader)
  }
}
</script>

<style lang="less" scoped>
.box {
  height: 100px;
  font-size: 36px;
  display: flex;
  align-items: center;
  p {
    color: #4472c4;
    line-height: 100px;
  }
}
.circle {
  width: 2.73333vw;
  height: 2.73333vw;
  border-radius: 50%;
  background: linear-gradient(0deg, #36a5ed 0%, #724cfa 100%);
  -webkit-mask: radial-gradient(
    transparent,
    transparent 0.46667vw,
    #000 1.46667vw
  );
  margin: 0px 30px;
}
.ysz {
  // font-size: 60px;
  // color: #5b9bd5;
  // -webkit-text-stroke: 1px #93bce2;
  // -webkit-text-fill-color: transparent;
  // font-weight: bold;
  // text-shadow: 2px 2px 2px #5b9bd5, -2px -2px 2px #fff;
  font-size: 60px;
}
.headerNav {
  z-index: 999;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  min-height: 90px;
  padding: 10px 80px;
  background: #666;
  opacity: 0.8;
  .nav {
    display: flex;
    div {
      // color: transparent;
      // -webkit-text-fill-color: rgb(255, 255, 255);
      // font-weight: 700;
      text-shadow: 1px 1px 1px #666;
      // -webkit-text-stroke: 1px rgb(200, 200, 200);
      font-size: 16px;
      color: rgb(250, 250, 250);
      margin: 0px 0px 0px 40px;
    }
    div:hover {
      font-size: 18px;
      color: #fff;
    }
  }
}

.container {
  position: relative;
  overflow: hidden;
  .seo {
    position: absolute;
    left: -9999px;
  }
  a {
    cursor: default;
  }
  width: 100%;
  height: 100%;
  .link_info_container {
    background: #e8e9eb;
    .link_info {
      justify-content: space-between;
      align-content: space-between;
      flex-wrap: wrap;
      height: 768px;
      padding: 20px;
      display: flex;
      .item {
        cursor: pointer;
        padding: 62px 0px 0px 72px;
        width: 49.5%;
        height: 355px;
        border-radius: 20px;
        border: 2px solid rgba(255, 255, 255, 1);
        box-shadow: 0px 2px 4px rgba(232, 233, 235, 1);
        &:hover {
          border: 4px solid #4f81ff;
        }

        .title {
          line-height: 34.75px;
          font-weight: 700;
          font-size: 24px;
          color: #344f98;
        }
        .info {
          width: 380px;
          font-size: 16px;
        }
        .infoBth {
          margin-top: 20px;
          font-weight: 700;
          font-size: 16px;
          i {
            font-weight: 700;
            font-size: 16px;
          }
        }
      }
      .one {
        background: url(../../../assets/首页/云平台服务.png) no-repeat center;
        background-size: cover;
      }
      .two {
        background: url(../../../assets/首页/AI智能识别算法.png) no-repeat
          center;
        background-size: cover;
      }

      .three {
        background: url(../../../assets/首页/AI条码秤解决方案.png) no-repeat
          center;
        background-size: cover;
      }

      .four {
        background: url(../../../assets/首页/3D双目摄像头.png) no-repeat center;
        background-size: cover;
      }
    }
  }
  .industry_solution_container {
    background: url(../../../assets//首页/行业解决方案.png) no-repeat center;
    background-size: cover;
    .industry_solution {
      position: relative;
      padding: 100px;
      height: 400px;
      color: #fff;
      .title {
        line-height: 34.75px;
        font-weight: 700;
        font-size: 24px;
      }
      .info {
        line-height: 20.27px;
        width: 434px;
        font-size: 14px;
      }
      .infoBth {
        cursor: pointer;
        margin-top: 20px;
        font-weight: 700;
        font-size: 16px;
        i {
          color: #00baad;
          font-weight: 700;
          font-size: 16px;
        }
      }
      .tab {
        position: absolute;
        top: 332px;
        left: 100px;
        // width: 271px;
        border-bottom: 1px solid #bcc5d8;
        display: flex;
        justify-content: space-between;
        .tabItem {
          cursor: pointer;
          margin-right: 64px;
          border-bottom: 2px solid #a6a6a6;
          font-weight: 700;
          font-size: 12px;
          line-height: 17.38px;
          color: #a6a6a6;
          &:last-child {
            margin-right: 0px;
          }
        }
        .activate {
          border-bottom: 2px solid #fff;
          color: #fff;
        }
      }
    }
  }
  .cameraSolutionsContainer {
    background: url(../../../assets//首页/3D双目摄像头解决方案.png) no-repeat
      center;
    background-size: cover;
    .cameraSolutions {
      position: relative;
      padding: 100px;
      height: 400px;
      color: #fff;
      .title {
        line-height: 34.75px;
        font-weight: 700;
        font-size: 24px;
      }
      .info {
        line-height: 20.27px;
        width: 434px;
        font-size: 14px;
      }
      .infoBth {
        cursor: pointer;
        margin-top: 20px;
        font-weight: 700;
        font-size: 16px;
        i {
          color: #00baad;
          font-weight: 700;
          font-size: 16px;
        }
      }
      .tab {
        position: absolute;
        top: 332px;
        left: 100px;
        border-bottom: 1px solid #bcc5d8;
        display: flex;
        justify-content: space-between;
        .tabItem {
          cursor: pointer;
          border-bottom: 2px solid #a6a6a6;
          margin-right: 64px;
          font-weight: 700;
          font-size: 12px;
          line-height: 17.38px;
          color: #a6a6a6;
          &:last-child {
            margin-right: 0px;
          }
        }
        .activate {
          border-bottom: 2px solid #fff;
          color: #fff;
        }
      }
    }
  }
  .company_introduction_container {
    background: url(../../../assets//首页/公司介绍底图.png) no-repeat center;
    background-size: cover;
    .company_introduction {
      padding: 38px 59px;
      position: relative;
      margin-top: 20px;
      height: 610px;
      .center_img {
        z-index: 99;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        width: 503px;
        height: 455px;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .title_box {
        display: flex;
        align-items: center;
        .title {
          font-size: 36px;
          font-weight: 700;
          color: #344f98;
          line-height: 52.13px;
        }
        .info {
          margin-left: 17px;
          font-size: 14px;
          color: #383838;
          line-height: 20.27px;
        }
      }
      .zh_text_info {
        text-align: justify;
        margin-bottom: 10px;
        margin-top: 10px;
        width: 258px;
        font-size: 14px;
        font-weight: 500;
        line-height: 20.27px;
        color: #383838;
      }
      .en_text_info {
        text-align: justify;
        margin-bottom: 10px;
        margin-top: 10px;
        width: 285px;
        font-size: 14px;
        font-weight: 500;
        line-height: 20.27px;
        color: #383838;
      }
      .leftTop {
        width: 568px;
        position: absolute;
        top: 133px;
        left: 101px;
        display: flex;
        flex-direction: column;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .leftBottom {
        position: absolute;
        top: 298px;
        left: 98px;
        width: 353px;
        display: flex;
        flex-direction: column;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .rightTop {
        width: 340px;
        position: absolute;
        bottom: 284px;
        right: 97px;
        display: flex;
        flex-direction: column;
        align-items: end;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .rightBottom {
        width: 529px;
        position: absolute;
        bottom: 147px;
        right: 141px;
        display: flex;
        flex-direction: column;
        align-items: end;
        img {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
}
.footer {
  background: #344f98;
  display: flex;
  padding: 30px 100px;
  justify-content: space-between;
  .left {
    width: 550px;
    justify-content: space-between;
    display: flex;
    .item {
      .title {
        font-size: 24px;
        color: #fff;
        margin-bottom: 10px;
      }
      div {
        line-height: 20.27px;
        font-size: 14px;
        color: rgba(255, 255, 255, 0.5);
      }
    }
  }
  .right {
    display: flex;
    color: #fff;
    .item {
      margin-right: 12px;
      width: 131px;
      height: 128px;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .text {
      font-size: 20px;
      text-align: center;
    }
  }
}
</style>
