<template>
  <div class="banner">
    <video
      src="https://web.cdn.osai.cc/media/video.29723cd7.mp4"
      autoplay
      loop
      muted
      poster="../../../../assets/img/banner/video_poster.png"
    ></video>
    <!-- <div class="videoMask"></div> -->
    <div class="banner_con w">
      <div class="banner_con_text">
        <transition name="fade">
          <div class="one" v-show="show">
            <h1 class="top">
              <span v-if="index == 0">OSAI</span
              >{{ $t(bannerConText[index].title) }}
            </h1>
            <h1 class="bottom">{{ $t(bannerConText[index].info) }}</h1>
          </div>
        </transition>
        <!-- <transition name="fade">
          <div class="one" v-if="bannerConText === 'one'">
            <div class="top"><span>OSAI</span>{{ $t('辨识一瞬间') }}</div>
            <div class="bottom">{{ $t('傲览方寸之间，视见万物乾坤') }}</div>
          </div>
        </transition> -->
        <!-- <transition name="fade">
          <div class="two" v-if="bannerConText === 'two'">
            <div class="top">{{ $t('AI收银解决方案') }}</div>
            <div class="bottom">
              {{
                $t(
                  '支持零代码弹窗插件无缝对接，提供多平台、多协议SDK集成方案。'
                )
              }}
            </div>
          </div>
        </transition> -->
        <!-- <transition name="fade">
          <div class="three" v-if="bannerConText === 'three'">
            <div class="top">{{ $t('AI条码秤解决方案') }}</div>
            <div class="bottom">
              {{ $t('算法、应用、硬件、OS及云平台，一站式解决方案。') }}
            </div>
          </div>
        </transition>
        <transition name="fade">
          <div class="four" v-if="bannerConText === 'four'">
            <div class="top">{{ $t('AI智能识别算法') }}</div>
            <div class="bottom">
              {{
                $t(
                  '自主研发多种类型AI视觉识别核心算法，满足各种行业视觉识别需求。'
                )
              }}
            </div>
          </div>
        </transition>
        <transition name="fade">
          <div class="five" v-if="bannerConText === 'five'">
            <div class="top">{{ $t('OSAI云平台') }}</div>
            <div class="bottom">
              {{
                $t(
                  '实现云端数据同步、设备监控、权限管理，建立高效的数据分析和管理体系。'
                )
              }}
            </div>
          </div>
        </transition>
        <transition name="fade">
          <div class="six" v-if="bannerConText === 'six'">
            <div class="top">{{ $t('行业解决方案') }}</div>
            <div class="bottom">
              {{ $t('基于AI核心算法，深入行业应用，解决用户痛点。') }}
            </div>
          </div>
        </transition> -->
      </div>
      <!-- 轮播图 -->
      <!-- <div class="viewpager">
        <el-carousel :interval="5000" type="card">
          <el-carousel-item>
            <div class="item_one" @click="to('/aiCashierSolutions')">
              <div class="text">
                <div class="title">AI收银解决方案</div>
                <div class="info">
                  <div>支持零代码弹窗插件无缝对接，</div>
                  <div>提供多平台、多协议SDK集成方案。</div>
                </div>
              </div>
            </div>
          </el-carousel-item>
          <el-carousel-item>
            <div class="item_two" @click="to('/aIBarcodeScaleSolution')">
              <div class="text">
                <div class="title">AI条码秤解决方案</div>
                <div class="info">
                  <div>算法、应用、硬件、OS及云平台，一站式解决方案。</div>
                </div>
              </div>
            </div>
          </el-carousel-item>
          <el-carousel-item>
            <div class="item_three" @click="to('/aiCoreAlgorithms')">
              <div class="text">
                <div class="title">AI智能识别算法</div>
                <div class="info">
                  <div>
                    自主研发多种类型AI视觉识别核心算法，满足各种行业视觉识别需求。
                  </div>
                </div>
              </div>
            </div>
          </el-carousel-item>
          <el-carousel-item>
            <div class="item_four" @click="to('/cloudPlatform')">
              <div class="text">
                <div class="title">OSAI云平台</div>
                <div class="info">
                  <div>实现云端数据同步、设备监控、权限管理，</div>
                  <div>建立高效的数据分析和管理体系。</div>
                </div>
              </div>
            </div>
          </el-carousel-item>
          <el-carousel-item>
            <div class="item_five" @click="to('/industrySolution')">
              <div class="text">
                <div class="title">行业解决方案</div>
                <div class="info">
                  <div>基于AI核心算法，深入行业应用，解决用户痛点。</div>
                </div>
              </div>
            </div>
          </el-carousel-item>
        </el-carousel>
      </div> -->
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      index: 0,
      bannerConText: [
        { title: '辨识一瞬间', info: '傲览方寸之间，视见万物乾坤' },
        {
          title: 'AI收银解决方案',
          info: '支持零代码弹窗插件无缝对接，提供多平台、多协议SDK集成方案。'
        },
        {
          title: 'AI条码秤解决方案',
          info: '算法、应用、硬件、OS及云平台，一站式解决方案。'
        },
        {
          title: 'AI智能识别算法',
          info: '自主研发多种类型AI视觉识别核心算法，满足各种行业视觉识别需求。'
        },
        {
          title: 'OSAI云平台',
          info: '实现云端数据同步、设备监控、权限管理，建立高效的数据分析和管理体系。'
        },
        {
          title: '行业解决方案',
          info: '基于AI核心算法，深入行业应用，解决用户痛点。'
        }
      ],
      show: true,
      timerId: '' //定时器id
    }
  },
  mounted() {
    this.timerId = setInterval(() => {
      this.show = false
      setTimeout(() => {
        this.index++
        if (this.index >= this.bannerConText.length) {
          this.index = 0
        }
        this.show = true
        // this.bannerConText = this.banner[this.index]
      }, 2000)
    }, 5000)
  },
  // created() {},
  destroyed() {
    clearInterval(this.timerId)
  },
  methods: {
    to(val) {
      this.$router.push(val)
    }
  }
}
</script>

<style lang="less" scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 1s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
.el-carousel__item img {
  width: 100%;
  height: 100%;
}

/deep/ .el-carousel__button {
  background: #ccc;
  height: 3px;
}
.banner {
  position: relative;
  width: 100%;
  height: 100vh;
  video {
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: 50% 60%;
  }
  /*ios视频出现播放按钮*/

  video::-webkit-media-controls {
    display: none !important;

    -webkit-appearance: none;
  }

  video::-webkit-media-controls-panel {
    display: none !important;

    -webkit-appearance: none;
  }

  video::-webkit-media-controls-play-button {
    display: none !important;

    -webkit-appearance: none;
  }

  video::-webkit-media-controls-start-playback-button {
    display: none !important;

    -webkit-appearance: none;
  }

  video::-webkit-media-controls-enclosure {
    /*禁用播放器控制栏的样式*/

    display: none !important;
  }
  // .videoMask {
  //   position: absolute;
  //   width: 100%;
  //   height: 100%;
  //   background: url(../assets/img/banner/home-banner.jpg) no-repeat;
  //   background-size: cover;
  //   opacity: 0.9;
  // }
  .banner_con {
    padding: 0px 50px;
    position: absolute;
    top: 50%;
    right: 50%;
    transform: translate(50%, -50%);
    display: flex;
    justify-content: center;
    .banner_con_text {
      text-align: center;
      display: flex;
      flex-direction: column;
      color: #fff;
      .top {
        font-size: 90px;
        line-height: 130.32px;
        span {
          font-weight: 900;
        }
      }
      .bottom {
        font-size: 30px;
        text-align: center;
        line-height: 43.44px;
      }
    }
  }
}
</style>
